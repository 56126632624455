// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails";
import "./controllers/site";
import "./initializers";
import "./site";

// TODO: DAQUI PRA BAIXO NÃO ERA PARA ESTAR AQUI E SIM EM UM ARQUIVO SEPARADO
// TEM QUE ESTAR TUDO DENTRO DO TURBO:LOAD
document.addEventListener("turbo:load", () => {
  $("#user_password").keyup((e) => {
    if (/[#?!@$%^&*-]/.test(e.target.value)) {
      $("#special_caracter").removeClass("text-danger");
      $("#special_caracter").addClass("text-muted");
    } else {
      $("#special_caracter").addClass("text-danger");
      $("#special_caracter").removeClass("text-muted");
    }

    if (/[A-Z]/.test(e.target.value)) {
      $("#capital_caracter").removeClass("text-danger");
      $("#capital_caracter").addClass("text-muted");
    } else {
      $("#capital_caracter").addClass("text-danger");
      $("#capital_caracter").removeClass("text-muted");
    }

    if (/[a-z]/.test(e.target.value)) {
      $("#tiny_caracter").removeClass("text-danger");
      $("#tiny_caracter").addClass("text-muted");
    } else {
      $("#tiny_caracter").addClass("text-danger");
      $("#tiny_caracter").removeClass("text-muted");
    }

    if (/.{6,}/.test(e.target.value)) {
      $("#minimum_caracter").removeClass("text-danger");
      $("#minimum_caracter").addClass("text-muted");
    } else {
      $("#minimum_caracter").addClass("text-danger");
      $("#minimum_caracter").removeClass("text-muted");
    }

    // $("#minimum_caracter").addClass("text-danger")
    // $("#capital_caracter").addClass("text-danger")
    // $("#tiny_caracter").addClass("text-danger")
  });

  function getLatLng(zipCode) {
    $.get("/get_lat_lng_by_cep?zip_code=" + zipCode, function (data) {
      console.log(data);
      if (data) {
        $("#lat_address_field").val(data.lat);
        $("#lng_address_field").val(data.lon);
      }
    });
  }

  $(".order_search").change(function (e) {
    const last_url = ["/10", "/20", "ery", "tos"];
    let currentLocation = window.location.href;

    if (last_url.includes(currentLocation.slice(-3))) {
      currentLocation += "?";
    }
    let ret = currentLocation.replace("&q%5Bs%5D=price+asc", "");
    ret = currentLocation.replace("&q%5Bs%5D=price+asc", "");
    ret = ret.replace("&q%5Bs%5D=price+desc", "");
    ret = ret.replace("&q%5Bs%5D=created_at+desc", "");
    ret = ret.replace("&q%5Bs%5D=promotional_price+asc", "");
    const url_search = ret + "&q%5Bs%5D=" + e.target.value;
    window.location = url_search;
  });

  $(document).ready(function () {
    // console.log("AQUI TBEM");
    $(".state_search").change(function () {
      console.log("SSTATE SEARCH");
      let id_state = $(".state_search").val();
      $.get("/list/cities/" + id_state, function (data) {
        let x;
        $("#city").html("");
        $("#city").append("<option value> Selecione a cidade </option >");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#city").append(option);
        }
      });
    });

    $(".cep_search").keyup(function () {
      const cep = $(this).val();
      const filter_cep = cep.replace(/\D/g, "");
      if (filter_cep.length == 8) {
        $.get(
          "https://viacep.com.br/ws/" + filter_cep + "/json",
          {},
          function (data) {
            if (data != 0) {
              console.log(data);
              getLatLng(filter_cep);
              $(".neighborhood_search").val(data.bairro);
              $(".street_search").val(data.logradouro);
              $(".state_search").val(data.uf);
              $(".city_search").val("");
              $(".city_search").html("");
              $(".city_search").append(
                '<option value="">Selecione a Cidade</option>'
              );
              data.cities.forEach(function (element) {
                var newOption = new Option(
                  element.name,
                  element.id,
                  false,
                  false
                );
                // Append it to the select
                $(".city_search").append(newOption);
              });
              $(".city_search").val(data.city);
            } else {
              // console.log("cep error");
            }
          }
        );
      }
    });
  });
}); // END TEM QUE ESTAR TUDO DENTRO DO TURBO:LOAD
